.opened-statistics-grid {
}
.opened-statistics-grid td{
   min-height: 70px;
}
.opened-statistics-grid col{
   min-height: 70px;
}


.opened-statistics-grid .k-grid-aria-root {
    min-width: 100%;
    width: 1500px;
    max-width: 100%;
}

.opened-statistics-grid .k-table-tfoot  {
    background-color: #f8f9fa !important;
}
.opened-statistics-grid .k-grid-content {
    overflow: auto !important;
}

.opened-statistics-grid .k-grid-footer {
    padding-inline-end: 0px;
    background-color: #f8f9fa !important;

}