body {
    margin: 0;
    /*    font-family: 'Work Sans', sans-serif,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;*/
    font-family: 'Inter', sans-serif,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
}

html {
    background-color: #EDEFF0
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.k-table-thead {
    color: #797E80 !important;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    text-transform: uppercase !important;
    background: none !important;
}
.k-table-thead .k-link:hover {
  background-color: transparent !important;
}

input {
    border: 5px solid #e4e7eb;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
}
input:not(:placeholder-shown) {
    border-color: #006980;
}

td{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
td > .k-input-solid {
    border-color: #006980;
}

.k-grid{
    border: none !important;
}

 .k-grid  td {
    padding: 0px;
  }

  .k-grid .k-table{
    border-collapse:collapse !important
 }

.k-grid td{
}
.k-grid .k-grid-header{
    background:none;
    border: none;
}
.k-table-th{
    border: none !important;
}
.k-grid-header-wrap{
    border: none !important;

}

.table-numeric{
    text-align: end !important;
}

.grid-table-header{

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-transform: uppercase;
color: #797E80;
}
th{

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-transform: uppercase;
color: #797E80;
}

.grid-row{
    border-bottom:  0.1px solid  #dee2e6;
    height: 34px !important;
}

.k-grid-header:hover{
}
.no-border{
    border: none !important;
    padding-left: 10px !important;
}
 .k-picker-solid{
   height: 32px !important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .grid-td-input{
    border: 1px solid #EDEFF0;
    border-radius: 4px;
    padding: 6px 10px;
    height: 32px;
    
  }
  /* .table-numeric .k-input{
    text-align: end;
  } */
  .k-table-tfoot{
    background-color: white !important;
    border: none !important;
  }
  .k-grid-footer{
        border: 0px;
        border-right-width: 0px;
        background-color: white !important;
  }
  .k-grid-footer td{
    border: none !important;
  }
  
  .k-grid-footer .k-grid-footer-wrap{
    border-inline-width: 0px !important;
    margin-top: 15px;
 }
 
 
 .k-form-field .error {
  border-color: red;
 }

 input[type="number"]::-webkit-inner-spin-button,
 input[type="number"]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }

input[type="number"] {
  -moz-appearance: textfield;
}
.k-grid-norecords-template{
  display: none;
}


.custom_detail_grid{
  overflow: hidden !important;
  margin-bottom: 15px;
  background-color: #f5fbfb;
}
.custom_detail_grid  .k-grid-content{
  overflow: hidden !important

}
.custom_detail_grid .k-grid-header{
  padding: 0px
}
.custom_detail_grid thead{
  border-bottom:  2px solid #dee2e6 !important;
}
.hide_over_flow {
  overflow: hidden !important
}

.hide_over_flow .k-grid-content{
  overflow: hidden !important

}

.k-form  {
  width: 100%;
 overflow-x: auto;
}
.user-setting-popup{
  border-radius: 12px !important;
}


.org-name:hover{
   background-color: #f8f9fa !important;
}
.org-name:focus{
   background-color: #f8f9fa !important;
}

.fontRobot16{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
   font-weight: 400;
}
.fontWork28{
  font-family: 'Work Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #232626;
}

td{
  padding-left: 5px;
  padding-right: 6px;
}

.page-header{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.filter-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.filter-items{
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}
.filter-end-btn{
  display: flex;
   justify-content: flex-end;
   flex: 1;
   gap: 15px;
   margin-top: 24px ;
}

.info-error{
  font-family: 'Work Sans', sans-serif !important;

}
.info-error .k-window-title .k-dialog-title{
  color : red !important
}
.info-error .k-window-titlebar {
  color: red !important;
  min-width: 400px
}

.btn-style{
  height: 32px !important;
}
 .btn-style .k-input-md{
  height: 32px !important;
 }
 .k-input-md{
  height: 32px !important
 }


 
 .border-radius{
  border-radius: 12px; 
 }
 .k-card{
  border-radius: 12px; 
 }
 .home-card-item{
  padding: 40px;
  padding-top: 30px;
  border-radius: 12px !important; 

 }
 .home-card-item .k-card-body{
  padding: 0px;
 }