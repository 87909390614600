
  .custom_detail_grid{
    overflow: hidden !important;
  }
  .custom_detail_grid  .k-grid-content{
    overflow: hidden !important

  }
  .custom_detail_grid .k-grid-header{
    padding: 0px
  }
  .custom_detail_grid thead{
    border-bottom:  2px solid #dee2e6 !important;
  }
  .k-detail-cell{
     background-color: white;
  }


  tr .k-detail-cell{
    padding: 0px !important
  }


 .col-min-width{
  min-width: 200px
 }