.daily-fresh-sales-group-grid{
     
}



.daily-fresh-sales-group-grid td.k-table-td.k-group-cell {
  display: none;
  
} 
.daily-fresh-sales-group-grid tr.k-table-row > th.k-table-th.k-header:first-child {
  display: none;
}

/* Second child */
/* .daily-fresh-sales-group-grid tr.k-table-row > th.k-table-th.k-header:nth-child(2) {
  display: none;
  width: 0 !important;

}  */

.daily-fresh-sales-group-grid colgroup  col:nth-last-child(-n+2) {
width: 0 !important;
}

.daily-fresh-sales-group-grid colgroup col:nth-child(1){
width: auto !important;
}

.daily-fresh-sales-group-grid colgroup col:nth-child(2) {
width: 150px !important; 
}

.daily-fresh-sales-group-grid colgroup col:nth-child(3) {
width: 150px !important;
}


.daily-fresh-sales-group-grid colgroup col:nth-child(4  ) {
width: 150px !important;
}
.daily-fresh-sales-group-grid colgroup col:nth-child(5 ) {
width: 20px !important;
}


.daily-fresh-sales-group-grid .k-svg-icon {
display: none;
}
.daily-fresh-sales-group-grid td  {
/* display: block; */
}