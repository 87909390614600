
.PageContainer {
    padding: 40px;
    border-radius: 12px;
    background-color: white;
    /* display: flex; */
    /* flex-direction: column; */
    height: calc(100% - 90px);
}

.GridContainer {
    margin-bottom: 30px;
    max-height: 100%;
    height: 900px;
}
.TableHeader {
    color: #797E80;
    font-style: italic;
}


.Menulabel {
    color: #797E80;
    font-style: italic;
    font: 15px "Work Sans", sans-serif;
}

.BoxTextNote {
    font: 12px  'Roboto', sans-serif
}
.BoxHeadingSmall {
    font: 400 14px "Work Sans", sans-serif;
}
.BoxHeadingMedium {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    line-height: 26px;
    letter-spacing: -0.03em;
    color: #232626;
}
.BoxHeadingLarge {
    font: 400 32px "Work Sans", sans-serif;
    color: #006980
}

.InputLabel {
    color: #797E80;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
}

.TextButton {
    display: flex;
    gap: 6px;
    font-size: 16px;
    font-weight: 400 ;
    color: #232626;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.ButtonItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 17px 10px 20px !important;
    gap: 5px;

    height: 39px;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.ButtonLabel {
    font-family: 'Roboto', sans-serif;
     font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.RadioButton {

}
    .RadioButton > li > input:checked:focus {
        border-color: black;
        box-shadow:none
    }   
    .RadioButton > li > input:checked {
        border-color: black;
        background: #006980;
        background-color: white
    }
    .RadioButton > li > input {
        border-color: black;
        border-width:1.6px
    }
    .RadioButton > li > input:checked:after {
        content: "";
        display: block;
        width: 10px; 
        height: 10px; 
        background: #006980; 
        border-radius: 50%; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
    }
    .RadioButton > li > label {
        font: 400 16px 'Roboto', sans-serif;
  
    }

.TableText {
    color: #797E80;
    font: 400 14px 'Roboto', sans-serif;
}
.TableText td{
    height: 22px !important;
    white-space: nowrap !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
}
.TextRobot{
    font-family: 'Roboto', sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;
}

.TableTextBlack {
    color: #333333;
    font: 400 14px 'Roboto', sans-serif;
}

.Slider > ol li:first-child {
    background-color: red;
    max-width: fit-content !important
}

.CustomText {
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #232626;

}

.tableColgroupColFirstChild {
  }
  
  .tableColgroupColFirstChild table colgroup col:first-child {
    width: 0px;
  }

.tableColgroupColFirstChild {
  }
  
  .tableColgroupColFirstChild table colgroup col:first-child {
    width: 0px;
  }
  
  .HideScroll{
    overflow: hidden !important;
  }
  .HideScroll  .k-grid-content{
    overflow: hidden !important
  
  }
  .HideScroll .k-grid-header{
    padding: 0px
  }
  .HideScroll thead{
    border-bottom:  2px solid #dee2e6 !important;
  }
 