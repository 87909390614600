.App {
    text-align: center;
}
body {
    
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    background-color: #151950;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-box {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 32px;
    background-color: white
}

.search-input {
    border: none;
    height: auto;
    outline: none;
    padding: 5px;
    font-size: 16px;
    width: 120px
}

.search-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.search-icon {
    width: 20px; 
    height: 20px; 
}

.height-inherit {
    height: inherit
}