.frozen-sales-weekly-group-grid{
     
}


.frozen-sales-weekly-group-grid td.k-table-td.k-group-cell {
  display: none;
  
} 
.frozen-sales-weekly-group-grid tr.k-table-row > th.k-table-th.k-header:first-child {
  display: none;
  
}

/* Remove the tr that the group name was supposed to be, which is the first tr */

.frozen-sales-weekly-group-grid .grid-row:first-child  {
  display: none !important;

}

.frozen-sales-weekly-group-grid colgroup  col:nth-last-child(-n+2) {
width: 0 !important;
}

.frozen-sales-weekly-group-grid colgroup col:nth-child(1){
width: auto !important;
}

.frozen-sales-weekly-group-grid colgroup col:nth-child(2) {
width: 100px !important; 
}

.frozen-sales-weekly-group-grid colgroup col:nth-child(3) {
  width: auto !important;
}


.frozen-sales-weekly-group-grid colgroup col:nth-child(4  ) {
width: 150px !important;
}
.frozen-sales-weekly-group-grid colgroup col:nth-child(5 ) {
  width: 150px !important;
}
.frozen-sales-weekly-group-grid colgroup col:nth-child(6 ) {
  width: 100px !important;
}
.frozen-sales-weekly-group-grid colgroup col:nth-child(7 ) {
  width: 100px !important;
}
.frozen-sales-weekly-group-grid colgroup col:nth-child(8 ) {
  width: auto !important;
}
.frozen-sales-weekly-group-grid colgroup col:nth-child(9 ) {
  width: auto !important;
}
.frozen-sales-weekly-group-grid colgroup col:nth-child(10 ) {
  width: 100px !important;
}
.frozen-sales-weekly-group-grid colgroup col:nth-child(11 ) {
  width: 100px !important;
}


.frozen-sales-weekly-group-grid .k-svg-icon {
display: none;
}
.frozen-sales-weekly-group-grid td  {
/* display: block; */
}