
.CardBodyHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px
}

.CardItem {
    border-radius: 12px !important;
    padding: 25px;
    padding-top: 20px
   
}
