
  .custom_detail_grid{
    overflow: hidden !important;
  }
  .custom_detail_grid  .k-grid-content{
    overflow: hidden !important

  }
  .custom_detail_grid .k-grid-header{
    padding: 0px
  }
  .custom_detail_grid thead{
    border-bottom:  2px solid #dee2e6 !important;
  }
  .k-detail-cell{
     background-color: white;
  }


  tr .k-detail-cell{
    padding: 0px !important
  }



 
td {
  border: none !important;
  padding-left: 10px !important;
}

 .td-bold{
   color: black;
   font-weight: bold !important;
   padding-bottom: 30px !important;

 }

.td-bold-group {
  color: black;
  font-weight: bold !important;
  overflow: visible;
  white-space: nowrap;

  
}

 tr:has(> .td-bold) {
  border: none;

}