.sales-statistics-grid {
}




.sales-statistics-grid .k-grid-aria-root {
    min-width: 100%;
    width: 1500px;
    max-width: 100%;
}

.sales-statistics-grid .k-table-tfoot  {
    background-color: #f8f9fa !important;
}
.sales-statistics-grid .k-grid-content {
    overflow: auto !important;
}

.sales-statistics-grid .k-grid-footer {
    padding-inline-end: 0px;
    background-color: #f8f9fa !important;

}

.sales-statistics-grid td.product-name {
   width: auto;
   min-width: 150px !important;
}
.sales-statistics-grid .k-reset {
   font-weight: bold;
}