

.k-panelbar .k-panelbar-group > .k-panelbar-item   {
}

.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
    padding-left: 50px !important
}
.k-selected .k-panelbar-item-text {
    color: inherit !important;
}

.k-panelbar-item-text {
 
    height: 18px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    letter-spacing: -0.02em;
    color: #232626;

}

.k-panelbar-item > .k-link {
    background-color: transparent !important;
    width: 100%;
    height: 38px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    margin-top: 7px;
    margin-bottom: 7px
}

.k-selected {
    color: white !important;
    background-color: #006980 !important;
    height: 38px !important;
}
.k-link:hover {
    background-color: snow !important;
}
.k-link.k-selected {
    background-color: #006980 !important;
    height: 38px;
}


.k-slider {
    width:100% !important;

}
.k-slider .k-slider-track {
    height: 7px
}

.k-input-solid:focus-within {
    box-shadow: none;
    color: transparent
}

.k-picker-solid.selected {
    border-radius: 4px;
    border-color: #006980
}

.stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.step {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-width: 2px;
    border-color: #D9DBDC;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

    .step.active {
        border-color: #006980;
    }

.step.complete {
    color: white;
    background-color: #006980;
}

.connector {
    flex: 1;
    height: 2px;
    background-color: #ccc;
}

.connector.active {
    background-color: #006980;
    color: #006980
}

.step-name {
    margin-top: 5px;
    width: 50px;
    white-space: nowrap;
}

.step-index {
    color: #D9DBDC
}
.step-index.active {
    color: #006980
}
.step-index.complete {
    color: white;
    background-color: #006980;
}


.form_dropdown{

}
.input-border {
    border-radius: 5px !important;
    border-width: 2px !important;
}
    .input-border.selected {
        border-color: #006980 !important;
    }