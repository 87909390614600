.pick-list-group-grid{
     
}


.pick-list-group-grid td.k-table-td.k-group-cell {
  display: none;
  
} 
.pick-list-group-grid tr.k-table-row > th.k-table-th.k-header:first-child {
  display: none;
}

/* Second child */
.pick-list-group-grid tr.k-table-row > th.k-table-th.k-header:nth-child(2) {
  display: none;
  width: 0 !important;

} 

.pick-list-group-grid colgroup  col:nth-last-child(-n+2) {
width: 0 !important;
}


.pick-list-group-grid colgroup col:nth-child(1){
width: 150px !important;
}

.pick-list-group-grid colgroup col:nth-child(2) {
width: 100px !important;
}
.pick-list-group-grid colgroup col:nth-child(3) {
width: 100px !important;
}

.pick-list-group-grid colgroup col:nth-child(5) {
width: auto;
}
.pick-list-group-grid colgroup col:nth-child(6) {
width: 150px !important;

}
.pick-list-group-grid colgroup col:nth-child(7) {
    width: 150px !important;

}
.pick-list-group-grid colgroup col:nth-child(8) {
width: 20px;

}

.pick-list-group-grid .k-svg-icon {
display: none;
}
.pick-list-group-grid td  {
/* display: block; */
}