.auth-container {
  
}

.icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #aaa;
}


input {
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E3E5E6;
}
  .error {
        border: 1px solid red !important;
    }

input:focus {
    box-shadow: none !important
}

.input-container {

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 310px;
    min-width: 140px;
    background: #FFFFFF;
    border: 1px solid #E3E5E6;
    border-radius: 4px;
    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}


.button-containter {
    width:100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 0px;
    gap: 10px;
}

.input-container .k-checkbox-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* Grey 98 */
    color: #F7F9FA;
}

.input-container .k-checkbox {
    border: 2px solid white !important;
    background-color: transparent
}